import React, { useState } from "react";
import {
  Drawer,
  Button,
  Typography,
  IconButton,
  List,
  ListItem,
  ListItemPrefix,
  Chip,
} from "@material-tailwind/react";
import {
  PresentationChartBarIcon,
  WalletIcon,
  CreditCardIcon,
  PowerIcon,
  ChatBubbleLeftEllipsisIcon,
  ListBulletIcon,
} from "@heroicons/react/24/solid";
import { UserCircleIcon } from "@heroicons/react/24/outline";

export default function SideBar({ activePage, onButtonClick, setLoggedIn }) {
  const [open, setOpen] = React.useState(false);
  const openDrawer = () => setOpen(true);
  const closeDrawer = () => setOpen(false);
  const [hovered, setHovered] = useState(false);
  const [buttonWidth, setButtonWidth] = useState(2);
  const handleMouseEnter = () => {
    setHovered(true);
    setButtonWidth(8); // Yavaşça genişletmek için hedef genişlik
  };

  const handleMouseLeave = () => {
    setHovered(false);
    setButtonWidth(2); // Yavaşça daraltmak için hedef genişlik
  };
  const handleLogout = () => {
    setLoggedIn(false);
    sessionStorage.removeItem("loggedIn");
    sessionStorage.removeItem("userId");
    sessionStorage.removeItem("userType");
    sessionStorage.removeItem("userName");
    sessionStorage.removeItem("userFirstName");
    sessionStorage.removeItem("userLastName");
  };

  return (
    <React.Fragment>
      <div className="absolute bottom-3 left-4" style={{ zIndex: 999 }}>
        <Button
          variant="gradient"
          color="blue"
          style={{
            width: `${buttonWidth}rem`,
            transition: "width 1s ease",
          }}
          className={` font-[Inter] rounded-full flex flex-row items-center ${
            hovered ? "justify-end" : "justify-center"
          }`}
          onClick={openDrawer}
          onMouseEnter={handleMouseEnter}
          onMouseLeave={handleMouseLeave}
        >
          <div className="flex flex-row items-center justify-center">
            <ListBulletIcon className={`h-6 w-6 text-white`} />
            {buttonWidth === 8 && hovered ? (
              <Typography variant="h6">Menü</Typography>
            ) : null}
          </div>
        </Button>
      </div>

      <Drawer open={open} onClose={closeDrawer}>
        <div className=" flex justify-between  gap-2">
          <div className="flex flex-row items-center py-4 px-2">
            <UserCircleIcon className="h-16 text-indigo-500" />
            <div className="font-[Inter] flex flex-col text-xs pl-2 flex  justify-center">
              <Typography
                color="gray"
                style={{ fontWeight: "bold", fontFamily: "Inter" }}
                variant="small"
              >
                {sessionStorage.getItem("userFirstName")}{" "}
                {sessionStorage.getItem("userLastName")}
              </Typography>
              <Typography
                style={{ fontFamily: "Inter" }}
                color="gray"
                variant="small"
              >
                {sessionStorage.getItem("userName")}
              </Typography>
            </div>
          </div>
          <IconButton
            variant="text"
            className="transition duration-300"
            color="blue-gray"
            onClick={closeDrawer}
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
              strokeWidth={2}
              stroke="currentColor"
              className="font-[Inter] h-5 w-5"
            >
              <path d="M6 18L18 6M6 6l12 12" />
            </svg>
          </IconButton>
        </div>
        <Chip
          className="font-[Inter]  w-full border border-blue-500 rounded-sm flex items-center justify-center h-10 text-sm"
          variant="ghost"
          value="Ödeme İşlemlerİ"
          color="blue"
        />
        <List>
          <ListItem
            onClick={() => onButtonClick("Tekli Ödeme")}
            className={`font-[Inter] group cursor-pointer transition duration-300 ${
              activePage === "Tekli Ödeme"
                ? "bg-blue-500 text-white"
                : "hover:bg-blue-500 hover:text-white"
            }`}
          >
            <ListItemPrefix>
              <CreditCardIcon className="h-5 w-5" />
            </ListItemPrefix>
            Tekli Ödeme
          </ListItem>
          {sessionStorage.getItem("userType") === "admin" && (
            <ListItem
              onClick={() => onButtonClick("Toplu Ödeme")}
              className={` font-[Inter] group cursor-pointer transition duration-300 ${
                activePage === "Toplu Ödeme"
                  ? "bg-blue-500 text-white"
                  : "hover:bg-blue-500 hover:text-white"
              }`}
            >
              <ListItemPrefix>
                <WalletIcon className="h-5 w-5" />
              </ListItemPrefix>
              Toplu Ödeme
            </ListItem>
          )}

          <ListItem
            onClick={() => onButtonClick("Geçmiş Ödeme İşlemleri")}
            className={` font-[Inter] group cursor-pointer transition duration-300 ${
              activePage === "Geçmiş Ödeme İşlemleri"
                ? "bg-blue-500 text-white"
                : "hover:bg-blue-500 hover:text-white"
            }`}
          >
            <ListItemPrefix>
              <PresentationChartBarIcon className="h-5 w-5" />
            </ListItemPrefix>
            Geçmiş Ödemeler
          </ListItem>
          {sessionStorage.getItem("userType") === "admin" && (
            <ListItem
              onClick={() => onButtonClick("Sms Gönder")}
              className={` font-[Inter] group cursor-pointer transition duration-300 ${
                activePage === "Sms Gönder"
                  ? "bg-blue-500 text-white"
                  : "hover:bg-blue-500 hover:text-white"
              }`}
            >
              <ListItemPrefix>
                <ChatBubbleLeftEllipsisIcon className="h-5 w-" />
              </ListItemPrefix>
              Sms Gönder
            </ListItem>
          )}
          <ListItem
            onClick={() => {
              handleLogout();
            }}
            className={` font-[Inter] group cursor-pointer transition duration-300 ${
              activePage === "Çıkış Yap"
                ? "bg-blue-500 text-white"
                : "hover:bg-blue-500 hover:text-white"
            }`}
          >
            <ListItemPrefix>
              <PowerIcon className="h-5 w-5" />
            </ListItemPrefix>
            Çıkış Yap
          </ListItem>
        </List>
      </Drawer>
    </React.Fragment>
  );
}
