import React, { useState } from "react";
import {
  Card,
  CardHeader,
  CardBody,
  CardFooter,
  Input,
  Button,
  Alert,
} from "@material-tailwind/react";
import KonyaTurizm from "../images/konyaturizm.png";
import { API_IP, API_PORT } from "./AppConfig";

export function LoginScreen({
  setLoggedIn,
  setUserID,
  setUserType,
  setUserName,
  setUserFirstName,
  setUserLastName,
}) {
  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");
  const [loginError, setLoginError] = useState(false);

  // Kullanıcı adı ve şifre kontrolü
  const handleLogin = () => {
    fetch(
      `https://${API_IP}:${API_PORT}/user/findByUserNameAndPassword?username=${username}&password=${password}`,
      {
        method: "GET",
        headers: {},
      }
    )
      .then((response) => response.json())
      .then((data) => {
        setLoggedIn(true);
        setUserID(data.userId);
        setUserType(data.userType);
        setUserName(data.userName);
        setUserFirstName(data.userFirstName);
        setUserLastName(data.userLastName);
        sessionStorage.setItem("loggedIn", "true");
        sessionStorage.setItem("userId", data.userId);
        sessionStorage.setItem("userType", data.userType);
        sessionStorage.setItem("userName", data.userName);
        sessionStorage.setItem("userFirstName", data.userFirstName);
        sessionStorage.setItem("userLastName", data.userLastName);
      })
      .catch((error) => {
        console.error("API hatası:", error);
        setLoggedIn(false);
        setLoginError(true);
      });
  };

  return (
    <div className="font-[Inter] flex flex-col items-center w-full h-full justify-center gap-8 ">
      {loginError ? (
        <Alert className="w-96" color="amber">
          Kullanıcı adı veya şifre hatalı
        </Alert>
      ) : null}
      <Card className="w-96">
        <CardHeader
          variant="gradient"
          className="mb-4 grid  h-28 place-items-center"
        >
          <img src={KonyaTurizm} alt="KonyaTurizm" className="h-[60px]" />
        </CardHeader>
        <CardBody className=" font-[Inter] flex flex-col gap-4">
          <Input
            label="Kullanıcı Adı"
            size="lg"
            value={username}
            onChange={(e) => setUsername(e.target.value)}
          />
          <Input
            label="Şifre"
            size="lg"
            type="password"
            value={password}
            onChange={(e) => setPassword(e.target.value)}
            onKeyPress={(e) => {
              if (e.key === "Enter") {
                handleLogin();
              }
            }}
          />
        </CardBody>
        <CardFooter className="pt-0">
          <Button
            style={{ background: "#23B9CE", color: "white" }} // Set custom background color and text color
            variant="gradient"
            fullWidth
            onClick={handleLogin}
          >
            Giriş
          </Button>
        </CardFooter>
      </Card>
    </div>
  );
}
